import blog1 from "../../assets/blog1.png";
import blog2 from "../../assets/blog2.png";

let blogs = [
  {
    img: blog1,
    date: "April 03, 2023",
    head: "Lorem ipsum dolor sit amet",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean diam nunc, commodo ac nisi ut, facilisis lobortis est. Pellentesque sed neque at ipsum congue tincidunt.",
  },
  {
    img: blog2,
    date: "April 03, 2023",
    head: "Lorem ipsum dolor sit amet",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean diam nunc, commodo ac nisi ut, facilisis lobortis est. Pellentesque sed neque at ipsum congue tincidunt.",
  },
  {
    img: blog1,
    date: "April 03, 2023",
    head: "Lorem ipsum dolor sit amet",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean diam nunc, commodo ac nisi ut, facilisis lobortis est. Pellentesque sed neque at ipsum congue tincidunt.",
  },
  {
    img: blog2,
    date: "April 03, 2023",
    head: "Lorem ipsum dolor sit amet",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean diam nunc, commodo ac nisi ut, facilisis lobortis est. Pellentesque sed neque at ipsum congue tincidunt.",
  },
  {
    img: blog1,
    date: "April 03, 2023",
    head: "Lorem ipsum dolor sit amet",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean diam nunc, commodo ac nisi ut, facilisis lobortis est. Pellentesque sed neque at ipsum congue tincidunt.",
  },
  {
    img: blog2,
    date: "April 03, 2023",
    head: "Lorem ipsum dolor sit amet",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean diam nunc, commodo ac nisi ut, facilisis lobortis est. Pellentesque sed neque at ipsum congue tincidunt.",
  },
];

export { blogs };
