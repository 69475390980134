import img1 from "../../assets/number1.png";
import img2 from "../../assets/number2.png";
import img3 from "../../assets/number3.png";

let product = [
  {
    img: img1,
    head: "15k+",
    para: "Active user",
  },
  {
    img: img2,
    head: "30k",
    para: "Total Download",
  },
  {
    img: img3,
    head: "10k",
    para: "Customer",
  },
];

export { product };
